<template>
  <div class="terms" v-if="!isProcessing">
    <p class="terms__paragraph">この利用規約（以下、「本規約」といいます。）は、{{ companyName }}が運営する月額課金サービス「{{ serviceName }}」（以下、「本サービス」といいます。）を会員が利用する際の規約について定めるものです。
      本規約は、本サービスを利用するすべての会員に適用されるものとします。
    </p>
    <h2 class="terms__subtitle">第1条（定義）</h2>
    <p class="terms__paragraph">
      本規約において、用語の定義は以下のとおりとします。
      <ol class="terms__paragraph__list">
        <li>「本サービス」とは、{{ companyName }}が運営するプラットフォーム及びアプリケーションを通して、{{ companyName }}と会員又は会員同士が交流する場や、{{ companyName }}又は会員が運営する各種プロジェクトにおける取引や貢献、及び証明手段を提供するサービス「{{ serviceName }}」をいいます。</li>
        <li>「会員」とは、本サービスの会員登録（以下、「会員登録」といいます。）を完了し、会員登録を抹消されていない個人をいいます。</li>
        <li>「申込者」とは、会員登録を希望して本サービスへの入会申込を行う個人を言います。</li>
        <li>「配信情報」とは、{{ companyName }}が本サービスにおいて会員に対し配信する文章、画像、音声及び動画等を含む一切の情報をいいます。</li>
        <li>「会員投稿情報」とは、会員が本サービスにおいて投稿する文章、画像、音声及び動画等を含む一切の情報をいいます。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第2条（会員登録）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>申込者は{{ companyName }}に対し、{{ companyName }}が定める方法により本サービスへの入会申込を行うものとします。</li>
        <li>申込者は、入会申込をもって本規約を承諾したものとします。</li>
        <li>会員登録は、{{ companyName }}が当該会員入会を承認した時点で完了します。</li>
        <li>{{ companyName }}は、申込者に以下の事由があると判断した場合、入会を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>会員登録の申請に際して虚偽の事項を届け出た場合</li>
          <li>本規約に違反したことがある者からの申請である場合</li>
          <li>成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</li>
          <li>反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力との何らかの交流もしくは関与を行っていると{{ companyName }}が判断した場合</li>
          <li>その他、{{ companyName }}が会員登録を相当でないと判断した場合</li>
        </ol>
        <li>会員登録は、{{ companyName }}が退会手続きを完了、又は会員登録が抹消されない限り原則自動的に更新（以下「自動更新」といいます。）されるものとします。</li>
        <li>会員は、退会を希望する場合、{{ companyName }}が定める方法により退会手続きを行い、{{ companyName }}が退会手続きを完了することで、本サービスを退会し、会員登録を抹消できるものとします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第3条（利用料金等）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>本サービスを利用するにあたり{{ companyName }}に対して利用料金を前払いで支払う必要があり、会員は{{ companyName }}が定める利用料金を支払うことにより、会員登録の有効期間中、本サービス及び{{ companyName }}が定める会員特典を得ることができます。</li>
        <li>入会金はありません。</li>
        <li>利用料金は月額{{ price }}円（税込）です。会員は、{{ companyName }}が指定する方法により利用料金を支払うものとします。</li>
        <li>月途中の会員登録又は退会の場合であっても、入会月及び退会月の利用料金の日割清算は行わないものとします。</li>
        <li>{{ companyName }}は、会員が支払った利用料金及び購入代金について、返金を行わないものとします。</li>
        <li>会員が本サービスを有効期間内に自主退会、又は会員登録が抹消された場合であっても、前項と同様とします。</li>
        <li>自動更新される場合、利用料金は、有効期間が経過した時に、会員が会員登録の際に指定した支払方法により自動的に決済されるものとします。</li>
        <li>利用料金の支払方法は、原則としてクレジットカードとします。</li>
        <li>{{ companyName }}は、利用料金、会員コ―ス、又は会員特典を変更する場合、変更日の一ヶ月前までに会員に告知するものとします。ただし、やむを得ない事情が存する場合はこの限りではありません。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第4条（配信情報の利用）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>会員は、{{ companyName }}が配信する会員限定の配信情報を利用できますが、会員ではない第三者と共有することはできません。</li>
        <li>会員が自主退会した場合、又は会員登録が抹消された場合、当該会員は全ての配信情報を利用することができなくなります。</li>
        <li>{{ companyName }}は、配信情報の配信を自己の判断で停止又は終了する場合があります。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第5条（会員投稿情報及び配信情報等の取扱い）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>会員は、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た情報のみ、投稿または編集できるものとします。</li>
        <li>会員投稿情報の著作権その他一切の法的権利については、会員投稿情報を投稿した会員又は当該会員に利用を許諾した第三者に帰属します。</li>
        <li>{{ companyName }}は、本サービスの保守や改良等の必要が生じた場合には、会員投稿情報につき、サービスの保守や改良等に必要な範囲で複製等を行えるものとします。</li>
        <li>{{ companyName }}は、本サービスの広告及び本サービスにより派生する商品化及び役務化の目的のために、会員投稿情報を無償で利用（複製・複写・改変・第三者へのサブライセンスその他あらゆる利用を含みます。）できるものとし、会員は、これを{{ companyName }}に対し著作者人格権を行使せず、永続的かつ取消不能のものとして許諾するものとします。ただし、会員投稿情報のうち個人を特定することのできる情報や会員が限定公開の意図を明示して投稿した情報を利用する場合、{{ companyName }}は、当該情報を投稿した会員の事前の承諾を得るものとします。</li>
        <li>会員が退会した場合においても、当該会員による会員投稿情報は削除されず、他の会員において閲覧可能な状態として残ります。</li>
        <li>{{ companyName }}が配信する配信情報の著作権については、{{ companyName }}又は{{ companyName }}に利用を許諾した第三者に帰属します。</li>
        <li>{{ companyName }}は、会員に本サービスを快適にご利用いただくため、{{ companyName }}自ら又は第三者に委託して、会員投稿情報を監視することとし、会員はこれに同意するものとします。ただし、{{ companyName }}は監視義務を負うものではありません。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第6条（禁止事項等）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、会員による本サービスの利用に際して、以下の行為を禁止します。なお、以下の各号に定める禁止事項は、会員投稿情報にも適用されるものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>公序良俗、その他法令に違反する行為又は犯罪に結びつく行為及び当該行為を勧誘・幇助・強制・助長する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>援助交際・売春・買春等を勧誘・誘発・助長する行為、又は隠語全般含め曖昧な表現をして、それらを勧誘・誘発・助長するような投稿行為</li>
          <li>児童ポルノの頒布又は児童虐待を誘引するおそれのある行為</li>
          <li>反社会的勢力に対して直接または間接に利益又は便宜を供与する行為</li>
          <li>他の会員、{{ companyName }}又は第三者の著作権、商標権、特許権、実用新案権、プライバシー権、肖像権、パブリシティ権その他の権利を侵害する行為、又は侵害するおそれのある行為</li>
          <li>他の会員、{{ companyName }}又は第三者を誹謗中傷し、又は名誉もしくは信用を傷つける行為</li>
          <li>過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含め他人に不快感を与える表現を、投稿または送信する行為</li>
          <li>{{ companyName }}の許諾を得ずに自己又は第三者の商品やサービスの広告・宣伝・誘導を目的とする行為、又はその他スパムメール・チェーンメール等の勧誘を目的とする行為</li>
          <li>他の会員、{{ companyName }}又は第三者に不利益を与える行為</li>
          <li>他の会員に関する個人情報等を収集または蓄積する行為</li>
          <li>本サービスにより得た情報を転載又は引用及び他メディアへの掲載等をする行為</li>
          <li>事実に反する情報を他の会員に流布する行為</li>
          <li>他の会員による本サービスの利用を妨害する行為</li>
          <li>他の会員又は第三者になりすまして、本サービスを利用する行為</li>
          <li>各種オンラインサービスの定める規約等に反する行為</li>
          <li>配信情報の著作権、商標権等の知的財産権その他の権利を侵害し、又は侵害するおそれのある行為（配信情報を複製、改変、公衆送信、送信可能化、アップロード、レンタル、上映又は放送する行為がこれに該当しますが、これに限られるものではありません。）</li>
          <li>配信情報に施された技術的保護手段を回避する行為</li>
          <li>本サービスを通じて取得した個人情報を本人の同意なく第三者に提供する行為</li>
          <li>本サービスにより提供される情報を改ざん・消去する行為</li>
          <li>{{ companyName }}のサーバーに過度の負担を及ぼす行為</li>
          <li>本サービスに接続されている他のコンピューター・システム又はネットワークへの不正アクセスを試みる行為</li>
          <li>コンピューターウィルス等有害なプログラムを使用し、もしくは提供する行為、又はこれらの行為を推奨する行為</li>
          <li>本サービス又は本サービス上で使用されているソフトウエアをリバース・エンジニアリング、逆コンパイル、又は逆アセンブルする行為</li>
          <li>{{ companyName }}が関与する公開前のプロジェクトの内容を第三者に口外する行為</li>
          <li>運営および特定メンバーへの誹謗中傷、荒らし行為などコミュニティの秩序を大きく乱す行為</li>
          <li>本サービスの他、{{ companyName }}のサービスの運営を妨害・損害を与えるおそれのある行為</li>
          <li>前各号に定める行為を助長する行為</li>
          <li>前各号に定める行為と疑われる行為</li>
          <li>その他､{{ companyName }}が不適切と判断した行為</li>
        </ol>
        <li>前項各号の禁止事項に該当するか否かについては、{{ companyName }}の裁量により判断することができるものとします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第7条（会員登録の抹消）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、本サービスを適正に運営するため、会員が以下に定める事項に該当する場合には、事前の通知なく、会員投稿情報の削除、本サービスの利用停止、会員登録の抹消等、必要な措置を講じることができるものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>利用料金の支払いが滞っている場合</li>
          <li>会員が本規約に定められている事項に違反した場合、又はそのおそれがあると{{ companyName }}が判断した場合</li>
          <li>登録事項に虚偽の事実があることが判明した場合</li>
          <li>会員と{{ companyName }}との間の信頼関係が失われた場合もしくは{{ companyName }}が会員による本サービスの利用を不適当であると判断した場合</li>
          <li>破産、民事再生、会社更生または特別清算の手続開始決定等の申立がなされたとき</li>
          <li>{{ companyName }}からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合</li>
          <li>その他、{{ companyName }}が本サービスの利用を適当でないと判断した場合</li>
        </ol>
        <li>前項各号のいずれかに該当した場合、会員は、{{ companyName }}に対する一切の債務について期限の利益を失い、その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。</li>
        <li>{{ companyName }}は、本条に基づき{{ companyName }}が行った行為により会員に生じた損害について、一切の責任を負いません。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第8条（本サービスの提供の停止等）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、以下のいずれかに該当する場合、会員に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>本サービスにかかるシステムの保守点検または更新を行う場合</li>
          <li>地震、落雷、火災、風水害、停電等の天災事変その他非常事態の発生した場合</li>
          <li>システムまたは通信回線等が停止した場合</li>
          <li>各種オンラインサービスが停止した場合</li>
          <li>その他、{{ companyName }}が本サービスの提供が困難と判断した場合</li>
        </ol>
        <li>{{ companyName }}は、本サービスの停止、変更又は終了によって会員が被った損害を賠償する責任を負わないものとします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第9条（免責事項）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、以下に掲げる事項について、一切保証しないものとします。会員は、本サービスの利用及び本サービスにより提供される情報の有用性等を自己の判断、かつ責任で利用するものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>本サービスに事実上または法律上の瑕疵（有用性、適合性、完全性、正確性、信頼性、安全性、合法性、道徳性、最新性、エラー、バグ、障害、権利侵害などを含みます。）がないこと</li>
          <li>会員間のやりとりに関する一切の事項</li>
          <li>各種オンラインサービス上に掲載されている事項</li>
          <li>{{ companyName }}の配信情報が第三者の権利を侵害しないこと</li>
          <li>本サービスの存続又は同一性が維持されること</li>
        </ol>
        <li>会員は、{{ companyName }}他の会員との間で紛争が生じた場合には、当事者間においてこれを解決するものとします。</li>
        <li>{{ companyName }}は、本サービスに起因して会員に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する{{ companyName }}と会員との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</li>
        <li>前項ただし書に定める場合であっても、{{ companyName }}は、{{ companyName }}の過失（重過失を除きます。）による債務不履行または不法行為により会員に生じた損害のうち特別な事情から生じた損害（{{ companyName }}または会員が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、{{ companyName }}の過失（重過失を除きます。）による債務不履行または不法行為により会員に生じた損害の賠償は、会員から当該損害が発生した月に受領した利用料の額を上限とします。</li>
        <li>{{ companyName }}は、本サービスに関して、会員と他の会員または第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第10条（損害賠償）</h2>
    <p class="terms__paragraph">
      会員の行為（会員の行為が原因で生じたクレーム等を含みます。）に起因して{{ companyName }}に損害が発生した場合、{{ companyName }}は会員に対し、当該損害の全額（{{ companyName }}が負担する弁護士費用を含みます。）を賠償請求できるものとします。
    </p>
    <h2 class="terms__subtitle">第11条（個人情報の取扱い）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、申込者及び会員が本サービスを利用する際に{{ companyName }}に対して提供する個人情報を、{{ companyName }}の「プライバシーポリシー」の規定に則り、取り扱うものとします。なお、本条において個人情報とは、「個人情報保護に関する法律」に定められる個人情報をいいます。</li>
        <li>{{ companyName }}は、個人情報を、以下の各号に定める目的で利用することができるものとします。</li>
        <ol class="terms__paragraph__list__nested">
          <li>本サービスの提供を行う目的</li>
          <li>{{ companyName }}が行う申込者の入会審査に関する手続に協力する目的</li>
        </ol>
        <li>申込者及び会員は、{{ companyName }}が個人情報のうち前項に定める目的に必要な範囲の情報を、{{ companyName }}及び{{ companyName }}が指定する第三者に対し提供することに同意するものとします。</li>
        <li>申込者及び会員が{{ companyName }}を介することなく、{{ companyName }}に対し、直接自己の個人情報を提供する場合、{{ companyName }}はそれによって生じた紛争等には一切関知しないものとし、申込者及び会員に対し何等の責任を負わないものとします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第12条（規約の変更）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}は、{{ companyName }}が必要と判断した場合には、本規約及び{{ companyName }}規則等を、申込者及び会員に事前の通知なく変更することができるものとします。</li>
        <li>変更後の本規約については、本サービス上に表示した時点で効力を生じるものとし、本規約変更後に、申込者及び会員が本サービスを利用した場合には、申込者及び会員は変更後の本規約の内容を承諾したものとみなします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第13条（{{ companyName }}からの通知）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>{{ companyName }}から申込者及び会員への連絡事項については、会員登録の際に登録したメールアドレス又は各種SNS（以下、「登録情報」といいます。）を通じて行います。</li>
        <li>申込者及び会員は、前項の登録情報に変更がある場合、直ちに当サイトにて変更手続きをするものとします。</li>
        <li>申込者及び会員が前項に定める変更手続きを怠ったことにより、申込者及び会員に損害が生じたとしても、{{ companyName }}は何らの責任を負わないものとします。</li>
      </ol>
    </p>
    <h2 class="terms__subtitle">第14条（権利義務の譲渡禁止）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        会員は、本規約に基づく全ての契約について、その契約上の地位及びこれにより生じる権利義務の全部又は一部を、{{ companyName }}の書面による事前の承諾なく第三者に対し譲渡、移転、担保設定その他の処分をすることはできないものとします。
      </ol>
    </p>
    <h2 class="terms__subtitle">第15条（分離可能性）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        本規約のいずれかの規定が会員との本規約に基づく契約に適用される関連法令に反するとされる場合、当該規定は、その限りにおいて、当該会員との契約には適用されないものとします。ただし、この場合でも、本規約の他の規定の効力には影響しないものとします。
      </ol>
    </p>
    <h2 class="terms__subtitle">第16条（準拠法・裁判管轄）</h2>
    <p class="terms__paragraph">
      <ol class="terms__paragraph__list">
        <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
        <li>本サービスに関して紛争が生じた場合には、{{ companyName }}の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
      </ol>
    </p>
  </div>
</template>
<script>
export default {
  name: 'terms',
  async mounted () {
    const promises = []

    // 設定の基本情報が存在しない場合は取得
    const settingKey = ['companyName', 'serviceName']
    for (const key of settingKey) {
      if (!this[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} 運営の名前
     */
    companyName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyName')
      return data ? data.value : null
    },
    /**
     * @return {String} サービスの名前
     */
    serviceName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('serviceName')
      return data ? data.value : null
    },
    /**
     * @return {String} 表示金額
     */
    price () {
      const price = Number(process.env.VUE_APP_PRICE)
      return price.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.terms {
  padding: $padding_height $padding_width 100px;
  margin-top: $header_height;
  font-size: 1.1rem;
  &__subtitle {
    margin: 0;
  }
  &__paragraph {
    margin: 5px;
    &__list {
      margin: 2px 0;
      &__nested {
        padding: 0 0 0 2em;
        margin: 0;
      }
      &__nested li {
        list-style-position: inside;
        list-style-type: none;
        counter-increment: cnt;
      }
      &__nested li::before {
        display: marker;
        content: "(" counter(cnt) ") ";
      }
    }
  }
}
</style>
